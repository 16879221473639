
import Api from '../../api/Api'

const photo = {

    namespaced: true,

    state: {

        photos: [], 
        allphotos: [],
    },

    mutations: {

        GET_PHOTO (state, photos){
            state.photos = photos
        },

        ALL_PHOTO (state, allphotos){
            state.allphotos = allphotos
        },


    },

    actions: {

        getPhoto({ commit }) {

            Api.get('/homepage/photo')
            .then(response => {

                commit ('GET_PHOTO', response.data.data)

            }).catch(error => {
                console.log(error)
            })
        },

        allPhoto({ commit }) {

            Api.get('/photo')
            .then(response => {

                commit ('ALL_PHOTO', response.data.data)

            }).catch(error => {
                console.log(error)
            })
        },

    },

    getters: {

    },

}

export default photo