//import global API
import Api from '../../api/Api'

const post = {

    //set namespace true
    namespaced: true,

    //state
    state: {
    
        //index products
        posts: [],
        allposts: [],
        post: {}

    },

    //mutations
    mutations: {

        //set state products dengan data dari response 
        GET_POSTS(state, posts) {
            state.posts = posts
        },
        
         //set state products dengan data dari response 
         ALL_POSTS(state, allposts) {
            state.allposts = allposts
        },

        DETAIL_POST(state, post) {
            state.post = post
        },

    },

    //actions
    actions: {

        //action getProducts
        getPosts({ commit }) {

            //get data sliders ke server
            Api.get('/homepage/post')
            .then(response => {

                //commit ke mutation GET_POSTS dengan response data
                commit('GET_POSTS', response.data.data)

            }).catch(error => {

                //show error log dari response
                console.log(error)

            })
        },

         //action getProducts
         allPosts({ commit }) {

            //get data sliders ke server
            Api.get('/posts')
            .then(response => {

                //commit ke mutation GET_POSTS dengan response data
                commit('ALL_POSTS', response.data.posts)

            }).catch(error => {

                //show error log dari response
                console.log(error)

            })
        },

        getDetailPost ({ commit }, slug ) {

            Api.get(`/post/${slug}`) 

                .then(response => {

                    commit ('DETAIL_POST', response.data.data)
                }).catch( error => {
                    console.log(error)
                })
            
        },

       
    },

    //getters
    getters: {

    }

}

export default post
