<template>
 <link
              rel="stylesheet"
              href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
            />
 
   <div class="container-fluid">
                      
            <div style="
             background-image: url('https://img.freepik.com/free-vector/abstract-colorful-geometric-banner_1035-18980.jpg?w=1380&t=st=1656051384~exp=1656051984~hmac=9df3bf9555a5e319dbec1c8895720b50997cab3dfadb7d0b78bb288ef44ba9ac');     
            "           
            class="row text-center pb-3">
           
            
                <div class="col-md-12">
                   
                </div>
            </div>

        </div>

</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "MainHeader",

  setup() {
    //store vuex
    const store = useStore();

    //computed
    const isLoggedIn = computed(() => {
      //get getters isLoggedIn dari module auth
      return store.getters["auth/isLoggedIn"];
    });

    return {
      store,
      isLoggedIn,
    };
  },
};
</script>
