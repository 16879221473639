//import vue router
import { createRouter, createWebHistory } from 'vue-router'

//import store from vuex
import store from '@/store'

//define a routes
const routes = [

// halaman Frontend ==============================================================================
      
    {
        path: '/register',
        name: 'register',
        component: () => import( /* webpackChunkName: "register" */ '@/views/auth/Register.vue')
    },

    {
        path: '/login',
        name: 'login',
        component: () => import( /* webpackChunkName: "login" */ '@/views/auth/Login.vue')
    },

    {
        path: '/',
        name: 'home',
        component: () => import( /* webpackChunkName: "home" */ '@/views/home/Index.vue')
    },

    {
        path: '/about',
        name: 'about',
        component: () => import( /* webpackChunkName: "about" */ '@/views/home/About.vue')
    },

    {
        path: '/trauma',
        name: 'trauma',
        component: () => import( /* webpackChunkName: "trauma" */ '@/views/center/TraumaCenter.vue')
    },

    {
        path: '/childern',
        name: 'childern',
        component: () => import( /* webpackChunkName: "childern" */ '@/views/center/MomChildern.vue')
    },

    {
        path: '/vertility',
        name: 'vertility',
        component: () => import( /* webpackChunkName: "vertility" */ '@/views/center/Vertility.vue')
    },

    

    {
        path: '/product',
        name: 'product',
        component: () => import( /* webpackChunkName: "product" */ '@/views/product/Index.vue')
    },

    {
        path: '/post',
        name: 'post',
        component: () => import( /* webpackChunkName: "post" */ '@/views/post/Index.vue')
    },

    {
        path: '/event',
        name: 'event',
        component: () => import( /* webpackChunkName: "event" */ '@/views/event/Index.vue')
    },

    {
        path: '/photo',
        name: 'photo',
        component: () => import( /* webpackChunkName: "photo" */ '@/views/photo/Index.vue')
    },

    {
        path: '/video',
        name: 'video',
        component: () => import( /* webpackChunkName: "video" */ '@/views/video/Index.vue')
    },
   
    {
        path: '/room',
        name: 'room',
        component: () => import( /* webpackChunkName: "room" */ '@/views/room/Index.vue')
    },

    {
        path: '/facility',
        name: 'facility',
        component: () => import( /* webpackChunkName: "facility" */ '@/views/facility/Index.vue')
    },
    {
        path: '/doctor',
        name: 'doctor',
        component: () => import( /* webpackChunkName: "doctor" */ '@/views/doctor/Index.vue')
    },
    {
        path: '/schedule',
        name: 'schedule',
        component: () => import( /* webpackChunkName: "schedule" */ '@/views/schedule/Index.vue')
    },

    {
        path: '/doctor/:id',
        name: 'detail_doctor',
        component: () => import(/* webpackChunkName: "detail_doctor" */ '../views/doctor/Show.vue')
    },

    {
        path: '/appointment/:id',
        name: 'appointment',
        component: () => import(/* webpackChunkName: "appointment" */ '../views/appointment/Create.vue')
    },

    {
        path: '/appointment',
        name: 'show_appointment',
        component: () => import(/* webpackChunkName: "show_appointment" */ '../views/appointment/Index.vue')
    },

  
    {
        path: '/specialist/:id',
        name: 'detail_specialist',
        component: () => import(/* webpackChunkName: "detail_specialist" */ '../views/specialist/Show.vue')
      },
  

    {
        path: '/room/:slug',
        name: 'detail_room',
        component: () => import(/* webpackChunkName: "detail_room" */ '../views/room/Show.vue')
    },

    {
        path: '/product/:slug',
        name: 'detail_product',
        component: () => import(/* webpackChunkName: "detail_product" */ '../views/product/Show.vue')
    },

    {
        path: '/post/:slug',
        name: 'detail_post',
        component: () => import(/* webpackChunkName: "detail_post" */ '../views/post/Show.vue')
    },

    {
        path: '/event/:slug',
        name: 'detail_event',
        component: () => import(/* webpackChunkName: "detail_event" */ '../views/event/Show.vue')
    },

   
  
// Halaman Pasien =================================================================================

    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import( /* webpackChunkName: "login" */ '@/views/dashboard/Index.vue'),
        //chek is loggedIn
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/order',
        name: 'order',
        component: () => import(/* webpackChunkName: "order" */ '../views/order/Index.vue'),
        meta: {
          //chek is loggedIn
          requiresAuth: true
        }
      },
    {
        path: '/order/:snap_token',
        name: 'detail_order',
        component: () => import(/* webpackChunkName: "detail_order" */ '../views/order/Show.vue'),
        meta: {
          //chek is loggedIn
          requiresAuth: true
        }
    },

    {
        path: '/cart',
        name: 'cart',
        component: () => import(/* webpackChunkName: "cart" */ '../views/cart/Index.vue'),
        meta: {
          //chek is loggedIn
          requiresAuth: true
        }
    },

    {
        path: '/results',
        name: 'results',
        component: () => import(/* webpackChunkName: "results" */ '../views/result/Index.vue')
    },

    {
        path: '/result/:id',
        name: 'result_detail',
        component: () => import(/* webpackChunkName: "result_detail" */ '../views/result/Show.vue')
    },

    {
        path: '/patient',
        name: 'patient',
        component: () => import(/* webpackChunkName: "patient" */ '../views/patient/Index.vue')
    },

    {
        path: '/patient/create',
        name: 'patient_create',
        component: () => import(/* webpackChunkName: "patient_create" */ '../views/patient/Create.vue')
    },

    {
        path: '/patient/:id',
        name: 'patient_detail',
        component: () => import(/* webpackChunkName: "patient_detail" */ '../views/patient/Edit.vue')
    },
  

]



//create router
const router = createRouter({
    history: createWebHistory(),
    routes // <-- routes

    
})

//define route for handle authentication
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        //cek nilai dari getters isLoggedIn di module auth
        if (store.getters['auth/isLoggedIn']) {
            next()
            return
        }
        next('/login')
    }else {
        next()
    }
})

export default router
