
//import global API
import Api from '../../api/Api'

const room = {

    //set namespace true
    namespaced: true,

    state: {

        facilities: [],
    },

    mutations: {

        GET_FACILITIES(state, facilities) {
            state.facilities = facilities
        },
        
    },

    actions: {

        getFacilities({ commit }) {

            Api.get('/facility')
            .then(response => {

                commit('GET_FACILITIES', response.data.data)

            }).catch(error => {

                //show error log dari response
                console.log(error)

            })
        },

      
    },

    getters: {

    },
}

export default room