import Api from '../../api/Api'

const dashboard = {

    namespaced: true,

    state: {

        user: [],
        patients: [],
        order: {},
        appointment: {},

    },

    mutations: {

        GET_USER(state, user){
            state.user = user
        },

        SUM_PATIENT(state, patients) {
            state.patients = patients
        },

        SUM_ORDER(state, order) {
            state.order = order
        },

        SUM_APPOINTMENT(state, appointment) {
            state.appointment = appointment
        },


    },

    actions: {

        getDashboard({commit}) {

            Api.get('/dashboard') 
                .then(response => {

                    commit('GET_USER', response.data.user),
                    commit('SUM_PATIENT', response.data.patients),
                    commit('SUM_ORDER', response.data.order),
                    commit('SUM_APPOINTMENT', response.data.appointment)

                }).catch(error => {

                    console.log(error)

                })
        }

    },

    getters: {

    }

}

export default dashboard