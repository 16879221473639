//import global API
import Api from '../../api/Api'

const result = {

    //set namespace true
    namespaced: true,

    //state
    state: {

        //define state orders
        results: [],

        //define detil order
        resultDetail: {},

        resultFile: {}

       
    },

    //mutations
    mutations: {

        //GET ORDER
        GET_RESULTS(state, results) {
            state.results = results       //assign state order dari hasil response
        },

        DETAIL_RESULT(state, resultDetail) {
            state.resultDetail = resultDetail
        },

        RESULT_FILE(state, resultFile) {
            state.resultFile = resultFile
        },

    
    },

    //actions
    actions: {

        //action getResults
        getResults({ commit }) {

            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " +token

            Api.get('/results')
            .then(response => {

                //commit ke mutation GET_ORDER
                commit('GET_RESULTS', response.data.data)
            })
        },

        resultDetail({commit}, id) {

            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " +token

            Api.get(`result/${id}`)
            .then(response => {

                //commit ke mutation DETAIL_RESULT
                commit('DETAIL_RESULT', response.data.data)
                commit('RESULT_FILE', response.data.data.file)

            })
        }
    },

    //getters
    getters: {

          //getter getOrder
          getResults(state) {
            return state.results
          },

          resultDetail(state) {
            return state.resultDetail
          },

          resultFile(state) {
            return state.resultFile
          },
      

    }

}

export default result
