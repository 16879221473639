//import global API
import Api from '../../api/Api'

const drappointment = {

    namespaced: true,

    state: {

        //state untuk "token", pakai localStorage, untuk menyimpan informasi tentang token
        token: localStorage.getItem('token') || '',

        //state "user", pakai localStorage, untuk menyimpan data user yang sedang login
        user: JSON.parse(localStorage.getItem('user')) || {}, 
 
        patients: [],
        days: [],
        doctor: {},
        schedule: {},
        scheduleId: {},
        appointments: [],
        appointment:{},
     
    },

    mutations: {

        //update state user dari hasil response register / login
        GET_USER(state, user) {
            state.user = user // <-- assign state user dengan response data user
        },

        GET_PATIENTS(state, patients) {
            state.patients = patients
        },

        GET_DAYS(state,days) {
            state.days = days
        },

        GET_DOCTOR(state, doctor) {
            state.doctor = doctor
        },     

        GET_SCHEDULE(state, schedule) {
            state.schedule = schedule
        },

        SCHEDULE_ID (state, scheduleId) {
            state.scheduleId = scheduleId
        },

        GET_APPOINTMENT (state, appointment) {
            state.appointment = appointment
        },

        GET_APPOINTMENTS (state, appointments) {
            state.appointments = appointments
        },


    },

    actions: {

        //action getUser
        getUser({ commit }) {

            //ambil data token dari localStorage
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = `Bearer  ${token}`
            Api.get('/user')
            .then(response => {
                
                //commit ke mutatuin GET_USER dengan hasil response
                commit('GET_USER', response.data)
                commit('GET_PATIENTS', response.data.user)
                
            })
        },

        getDays({ commit }) {

            Api.get('/days')
            .then(response => {

                commit('GET_DAYS', response.data.data)

            }).catch(error => {

                //show error log dari response
                console.log(error)

            })
        },

        getDetailSchedule({ commit }, id) {

            Api.get(`/schedule/${id}`)
            .then(response => {

                commit('GET_SCHEDULE', response.data.data)

                commit('GET_DOCTOR', response.data.data.doctor)

                commit('SCHEDULE_ID', response.data.data.id)

            }).catch(error => {

                //show error log dari response
                console.log(error)

            })
        },

        createAppointment({commit}, data) {

            //console.log('data')
             //define callback promise
             return new Promise((resolve, reject) => { 

                Api.post('/appointment', {

                    patient_id: data.patient_id,
                    schedule_id: data.schedule_id,
                    day_id: data.day_id,
                    register_date: data.register_date,
                    register_time: data.register_time,
    
                })
                .then(response => { 
                
                  const appointment = response.data
                
                  commit('GET_APPOINTMENT', appointment)
                  //resolve ke component dengan hasil response

                  resolve(response)

                }).catch(error => {

                  //reject ke component dengan hasil response
                  reject(error.response.data)

                })

             })

            
        },

        getAppointments({ commit }) {

             //define variable token
             const token = localStorage.getItem('token')

             Api.defaults.headers.common['Authorization'] = "Bearer " +token

            Api.get('/appointments')
            .then(response => {

                commit('GET_APPOINTMENTS', response.data.data)

            }).catch(error => {

                //show error log dari response
                console.log(error)

            })
        },

        


    },

    getters: {

         //scheduleId
         scheduleId(state) {
            return state.scheduleId
        }

    }

}

export default drappointment