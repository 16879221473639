//import axios
import axios from 'axios'

const Api = axios.create({
    //set default endpoint API
    baseURL: 'https://rs.mentariku.id/api'
    //baseURL: 'http://localhost:8000/api'
})

export default Api
