
import Api from '../../api/Api'

const slider = {

    //set namespace true
    namespaced: true,

    //state
    state: {
    
        sliders: [],

    },

    //mutations
    mutations: {

        GET_SLIDER(state, sliders) {
            state.sliders = sliders
        }
    },

    //actions
    actions: {

        getSliders({ commit }){

            Api.get('/slider')
            .then(response => {

                commit('GET_SLIDER', response.data.data)
            
            }).catch(error => {

                console.log(error)
            })
        }
    },

    //getters
    getters: {

    }

}

export default slider
