
//import global API
import Api from '../../api/Api'

const video = {

    //set namespace true
    namespaced: true,

    state: {

        videos: [],
    },

    mutations: {

        GET_VIDEOS(state, videos) {
            state.videos = videos
        },
        
    },

    actions: {

        getVideos({ commit }) {

            Api.get('/video')
            .then(response => {

                commit('GET_VIDEOS', response.data.data)

            }).catch(error => {

                //show error log dari response
                console.log(error)

            })
        },

    },

    getters: {

    }
}

export default video