
//import global API
import Api from '../../api/Api'

const room = {

    //set namespace true
    namespaced: true,

    state: {

        doctors: [],
        doctor: {},
        schedules: [],
    },

    mutations: {

        GET_DOCTORS(state, doctors) {
            state.doctors = doctors
        },

        GET_DOCTOR(state, doctor) {
            state.doctor = doctor
        },

        GET_SCHEDULE(state, schedules) {
            state.schedules = schedules
        },


        
    },

    actions: {

        allDoctors({ commit }) {

            Api.get('/doctors')
            .then(response => {

                commit('GET_DOCTORS', response.data.data)

            }).catch(error => {

                //show error log dari response
                console.log(error)

            })
        },

        getDetailDoctor({ commit }, id) {

            Api.get(`/doctor/${id}`)
            .then(response => {

                commit('GET_DOCTOR', response.data.data)

                commit('GET_SCHEDULE', response.data.data.schedule)

            }).catch(error => {

                //show error log dari response
                console.log(error)

            })
        },

      
    },

    getters: {

    },
}

export default room