
//import global API
import Api from '../../api/Api'

const room = {

    //set namespace true
    namespaced: true,

    state: {

        schedules: [],
    },

    mutations: {

      
        GET_SCHEDULE(state, schedules) {
            state.schedules = schedules
        },


        
    },

    actions: {

       
        getSchedules({ commit }) {

            Api.get('/schedules')
            .then(response => {

             
                commit('GET_SCHEDULE', response.data.data)

            }).catch(error => {

                //show error log dari response
                console.log(error)

            })
        },

      
    },

    getters: {

    },
}

export default room