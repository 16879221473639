//import global API
import Api from '../../api/Api'

const category = {

    //set namespace true
    namespaced: true,

    //state
    state: {
        
        //index categories
        categories: [],

    },

    //mutations
    mutations: {

        //set state categories dari hasil response
        GET_CATEGORIES(state, categories) {
            state.categories = categories
        }

    },

    //actions
    actions: {

        //action getCategories
        getCategories({commit}) {

            Api.get('/categories')
            .then(response => {

                //commit ke mutation
                commit('GET_CATEGORIES', response.data.categories)

            }).catch(error => {

                console.log(error)
            })
        },

    },

    //getters
    getters: {

        

    }

}

export default category
