//import global API
import Api from '../../api/Api'

const event = {

    //set namespace true
    namespaced: true,

    //state
    state: {
    
        //index events
        events: [],
        allevents: [],
        event: {}

    },

    //mutations
    mutations: {

        //set state events dengan data dari response 
        GET_EVENTS(state, events) {
            state.events = events
        },

        ALL_EVENTS(state, allevents) {
            state.allevents = allevents
        },

        DETAIL_EVENT(state, event) {
            state.event = event
        },

    },

    //actions
    actions: {

        //action getevents
        getEvents({ commit }) {

            //get data sliders ke server
            Api.get('/homepage/event')
            .then(response => {

                //commit ke mutation GET_events dengan response data
                commit('GET_EVENTS', response.data.data)

            }).catch(error => {

                //show error log dari response
                console.log(error)

            })
        },

        //action allevents
        allEvents({ commit }) {

            //get data events ke server
            Api.get('/events')
            .then(response => {

                commit('ALL_EVENTS', response.data.data)
            }).catch(error => {
                console.log(error)
            })
        },

        getDetailEvent({ commit }, slug ) {

            Api.get(`/event/${slug}`)
            .then( response => {
                commit ('DETAIL_EVENT', response.data.data)

            }).catch(error => {
                console.log(error)
            })
        },

    },

    //getters
    getters: {

    }

}

export default event
