
//import global API
import Api from '../../api/Api'

const room = {

    //set namespace true
    namespaced: true,

    state: {

        rooms: [],
    },

    mutations: {

        GET_ROOMS(state, rooms) {
            state.rooms = rooms
        },
        
    },

    actions: {

        allRooms({ commit }) {

            Api.get('/rooms')
            .then(response => {

                commit('GET_ROOMS', response.data.data)

            }).catch(error => {

                //show error log dari response
                console.log(error)

            })
        },

      
    },

    getters: {

    },
}

export default room