<template>

<div style="
             background-image: url('https://img.freepik.com/free-vector/elegant-white-background-with-shiny-lines_1017-17580.jpg?w=900&t=st=1656043278~exp=1656043878~hmac=6d12860ca16ff0c363ef768ae062bc78c19bed7f98272f69de6305acad871d3d');     
            "           
            class="row">
  <div class="container">
    <div class="row">
      <nav class="navbar navbar-expand-lg navbar-light">
       
        <router-link :to="{ name: 'home' }" class="navbar-brand"
                > <img src="@/assets/logo-rsm.png" alt="" width="145" height="47" /></router-link
              >
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav center">
           
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Dokter
              </a>
              <div class="dropdown-menu">
              
                 <router-link :to="{ name: 'doctor' }" class="nav-link">
                Profile</router-link
              >
               <router-link :to="{ name: 'schedule' }" class="nav-link">
                Jadwal</router-link
              >
               
              </div>
            </li>

            <li class="nav-item">
              <router-link :to="{ name: 'product' }" class="nav-link">
               Medical Checkup</router-link
              >
            </li>

            <li class="nav-item">
              <router-link :to="{ name: 'room' }" class="nav-link">
                Kamar</router-link
              >
            </li>

            <li class="nav-item">
              <router-link :to="{ name: 'facility' }" class="nav-link">
                Fasilitas</router-link
              >
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Media
              </a>

              <div class="dropdown-menu">
                <router-link :to="{ name: 'post' }" class="dropdown-item"
                  >Artikel</router-link
                >

                <router-link :to="{ name: 'event' }" class="dropdown-item"
                  >Agenda</router-link
                >

                <router-link :to="{ name: 'photo' }" class="dropdown-item"
                  >Foto</router-link
                >

                <router-link :to="{ name: 'video' }" class="dropdown-item"
                  >Vidio</router-link
                >
              </div>
            </li>

            <li class="nav-item">
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSdUkz-bu7zlpaID2pYxn-AWhhJAq87xh6SdgC2VFDLYTgQkGQ/viewform" 
              class="nav-link">Karir</a>
            </li>
          
          </ul>

          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <router-link
                class="nav-link"
                :to="{ name: 'login' }"
                v-if="!isLoggedIn"
              >
                <i class="fa fa-unlock-alt"></i> 
                 <span class="badge badge-warning ml-2"> Login </span>
              </router-link>
              <router-link class="nav-link" :to="{ name: 'dashboard' }" v-else>
                <i class="fa fa-user"></i> My Account
              </router-link>
            </li>

            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'cart' }">
                <i class="fa fa-shopping-bag"></i>
                <span class="badge badge-success ml-2"> {{ cartCount }} | Rp. {{ moneyFormat(cartTotal) }}</span>
              </router-link>
            </li>
          </ul>

        
        </div>
        
      </nav>

    
    </div>
  </div>

</div>

<div class="d-md-none d-sm-block">
  <RingBottomNavigation :options="options" v-model="selected" style="background-color:papayawhip" />
</div>


</template>

<script>
import { RingBottomNavigation } from "bottom-navigation-vue";
import "bottom-navigation-vue/dist/style.css";

import { computed, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "MainNavbar",

  components: { RingBottomNavigation },
    data: () => ({
    selected: 1,
    options: [
      { id: 1, icon: 'fas fa-stethoscope', title: 'Dokter', path: {name: "doctor"} },
      { id: 2, icon: 'fas fa-heartbeat', title: 'MCU', path: {name: "product"} },
      { id: 3, icon: 'fas fa-th-large', title: 'Home', path: {name: "home"} },
      { id: 4, icon: 'fas fa-calendar', title: 'Appointment', path: {name: "schedule"} },
      { id: 5, icon: 'fas fa-user', title: 'Akun', path: {name: "dashboard"}}

    
    ]
    }),

  setup() {
    //store vuex
    const store = useStore();

    //computed
    const isLoggedIn = computed(() => {
      //get getters isLoggedIn dari module auth
      return store.getters["auth/isLoggedIn"];
    });

    //cart count
    const cartCount = computed(() => {
      
      //get getter "cartCount" dari module "auth"
      return store.getters['cart/cartCount']
    })

    //cart total
    const cartTotal = computed(() => {

      //get getter "cartTotal" dari module "auth"
      return store.getters['cart/cartTotal']
    })

    //mounted
    onMounted(() => {
      window.scrollTo(0,0);
      //check state token
      const token = store.state.auth.token

      if(!token) {
        return
      }

      //set mounted, akan memanggil action "cartCount" didalam module "cart"
      store.dispatch('cart/cartCount')

      //state mounted, akan memanggil action "cartTotal" di module "cart"
      store.dispatch('cart/cartTotal')
    })

    return {
      store,
      isLoggedIn,
      cartTotal,
      cartCount
      
    }
  }
}
</script>
