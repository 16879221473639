//import vuex
import { createStore } from 'vuex'

//import module auth
import auth from './module/auth'

//import module order
import order from './module/order'

//import module product category
import category from './module/category'

//import module slider
import slider from './module/slider'

//import module product
import product from './module/product'

//import module post
import post from './module/post'

//import module photo
import photo from './module/photo'

//import module event
import event from './module/event'

//import module video
import video from './module/video'

//import module room
import room from './module/room'

//import module facility
import facility from './module/facility'

//import module facility
import doctor from './module/doctor'

//import module schedule
import schedule from './module/schedule'

//import module schedule
import specialist from './module/specialist'

import appointment from './module/appointment'

import cart from './module/cart'

import result from './module/result'

import patient from './module/patient'

import dashboard from './module/dashboard'


//create store vuex
export default createStore({

    modules: {
        auth,
        order,
        category,
        slider,
        product,
        post,
        photo,
        event,  
        video,  
        facility,   
        room,
        doctor,
        schedule,
        specialist,     
        appointment,
        cart,
        result,
        patient,
        dashboard,

    }

})
