<template>
  <MainHeader />
  <MainNavbar />

  		
<router-view />

  <MainFooter />
</template>

<script>

  import MainHeader from '@/components/Header'
  import MainNavbar from '@/components/Navbar'
  import MainFooter from '@/components/Footer'

  export default {

    components: {
      MainHeader,
      MainFooter,
      MainNavbar
    },


  }
</script>

