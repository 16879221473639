//import global API
import Api from '../../api/Api'

const specialist = {

    //set namespace true
    namespaced: true,

    //state
    state: {
    
       
        specialists: [],
        specialist: {},
        schedules: [],
      

    },

    //mutations
    mutations: {

        //set state products dengan data dari response 
        GET_SPECIALISTS(state, specialists) {
            state.specialists = specialists
        },

         //set state products dengan data dari response 
         GET_SPECIALIST(state, specialist) {
            state.specialist = specialist
        },

        GET_SCHEDULE(state, schedules) {
            state.schedules = schedules
        },
        
        
    },

    //actions
    actions: {

        //action getProducts
        getSpecialists({ commit }) {

            //get data sliders ke server
            Api.get('/specialists')
            .then(response => {

                //commit ke mutation GET_SPECIALISTS dengan response data
                commit('GET_SPECIALISTS', response.data.data)

            }).catch(error => {

                //show error log dari response
                console.log(error)

            })
        },

        getSchedules({ commit }, id) {

            Api.get(`/specialist/${id}`)
            .then(response => {

                commit('GET_SPECIALIST', response.data.data)

                commit('GET_SCHEDULE', response.data.data.schedule)

            }).catch(error => {

                //show error log dari response
                console.log(error)

            })
        },
       
    },

    //getters
    getters: {

    }

}

export default specialist
