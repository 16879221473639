//import global API
import Api from '../../api/Api'

const cart = {

    //set namespace true
    namespaced: true,

    //state
    state: {
    
        //cart
        cart: [],

        //total cart
        cartTotal: 0,

    },

    //mutations
    mutations: {

        //get data cart
        GET_CART(state, product) {
            state.cart = product
        },

        //get total cart
        TOTAL_CART(state, total) {
            state.cartTotal = total
        },

        //clear all cart
        CLEAR_CART(state) {
            state.cart      = []
            state.cartTotal = 0
            
        }

       

    },

    //actions
    actions: {

        //action addToCart
        addToCart({ commit }, { product_id, price, quantity}) {

            //get data token dan user
            const token = localStorage.getItem('token')
            const user  = JSON.parse(localStorage.getItem('user'))

            //set axios header dengan type Authorization + Bearer token
            Api.defaults.headers.common['Authorization'] = "Bearer " +token
            
            console.log(user.id)

            //send data cart ke server
            Api.post('/cart', {
                product_id: product_id,
                client_id: user.id,
                price: price,
                quantity: quantity,
                //weight: weight,
                
            })
            .then(() => {

                //get dat cart
                Api.get('/cart')
                .then(response => {
                    
                    //commit mutation GET_CART
                    commit('GET_CART', response.data.cart)

                })

                //get total cart
                Api.get('/cart/total')
                .then(response => {
                    
                    //commit mutation TOTAL_CART
                    commit('TOTAL_CART', response.data.total)

                })

            })
        },

        //cart count
        cartCount({ commit }) {

            //get data token dan user
            const token = localStorage.getItem('token')

            //set axios header dengan type Authorization + bearer token
            Api.defaults.headers.common['Authorization'] = "Bearer " +token

            //get data cart
            Api.get('/cart')
            .then(response => {

                //commit mutation GET_CART
                commit('GET_CART', response.data.cart)
            })
        },

        //cart total
        cartTotal({commit}) {

            //get data token dan user
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " +token

            Api.get('/cart/total')
            .then(response => {

                commit('TOTAL_CART', response.data.total)
            })
        },

        //remove cart
        removeCart({ commit }, cart_id) {

            //get data token dan user
            const token = localStorage.getItem('token')

            //set axios header dengan type Authorization + Bearer token
            Api.defaults.headers.common['Authorization'] = "Bearer " +token

            Api.post('/cart/remove', {
                cart_id: cart_id
            })
            .then(() => {

                //get cart
                Api.get('/cart')
                .then(response => {

                    commit('GET_CART', response.data.cart)
                })

                //get total cart
                Api.get('/cart/total')
                .then(response => {

                    commit('TOTAL_CART', response.data.total)
                })
            })
        },

        //checkout
        checkout({ commit }, data) {

            return new Promise((resolve, reject) => {

                Api.post('/checkout', {

                    patient_id:     data.patient_id,
                    register_date:  data.register_date,
                    register_time:  data.register_time,
                    grand_total:    data.grandTotal,
                })
                .then(response => {

                    resolve(response.data)

                    //remove all cart on database
                    Api.post('/cart/removeAll')
                    .then(() => {

                        commit('CLEAR_CART')
                    })
                    .catch(error => {
                        console.log(error)
                    })
                })
                .catch(error => {
                    reject(error)
                })
            })
        }

    },



    //getters
    getters: {

        //get cart
        getCart(state) {
            return state.cart
        },
        
        //count cart
        cartCount(state) {
            return state.cart.length
        },

        //cart total
        cartTotal(state) {
            return state.cartTotal
        }

    }

}

export default cart
