//import global API
import Api from '../../api/Api'

const patient = {

    //set namespace true
    namespaced: true,

    //state
    state: {

        //define state orders
        patients: [],
        patient: {},

           
    },

    //mutations
    mutations: {

        //GET PATIENTS
        GET_PATIENTS(state, patients) {
            state.patients = patients      
        },

        GET_PATIENT(state, patient) {
            state.patient = patient
        },

    },

    //actions
    actions: {

        //action getPatients
        getPatients({ commit }) {

            Api.get('/patients')
            .then(response => {

                //commit ke mutation GET_ORDER
                commit('GET_PATIENTS', response.data.patients)
            })
        },

        //action getPatientDetail
        getPatient({ commit }, id) {

            Api.get(`/patient/${id}`)
            .then(response => {

                commit('GET_PATIENT', response.data.data)

            }).catch(error =>{

               console.log(error)

            })
        },

        //action register
        register({commit}, patient) {

           return new Promise((resolve, reject ) => {

            Api.post('/patient', {

                nik: patient.nik,
                name: patient.name,
                phone: patient.phone,
                email: patient.email,
                dob: patient.dob,
                gender: patient.gender,
                marital: patient.marital,
                address: patient.address,
            })
            .then(response => {
                
                const patient = response.data

                commit ('GET_PATIENT', patient)

                resolve (response)

            }).catch(error => {

                reject(error.response.data)
            })

           })
        },

        //action update data patient
        update( {commit}, formData) {

            return new Promise((resolve, reject) => {

                Api.post('/patient/update', formData)

                .then(response => { 
                
                    const patient = response.data
                  
                    commit('GET_PATIENT', patient)
  
                    resolve(response)
  
                  }).catch(error => {
  
                    reject(error.response.data)
  
                  })
            })
        },

     
    },

    //getters
    getters: {

          //getter Patients
          getPatients(state) {
            return state.patients
          },

          getPatient(state) {
            return state.patient
          },
    }

}

export default patient
